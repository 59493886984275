<template>
  <div class="dropx-content" :class="{ 'dropx-mobile': isMobile }">
    <header>
      <div class="header">
        <div class="dropx-logo">
          <a href="/">
            <img width="120" src="~@/assets/images/logo.png" alt="DropX" />
          </a>
        </div>
        <wallet></wallet>
      </div>
    </header>
    <div class="dropx-box">
      <div class="dropx-main" v-if="step == 1">
        <div>
          <div class="dropx-title">
            <div class="font-bold">Network</div>

            <span style="color: rgba(255, 255, 255, 0.5); font-weight: normal !important">Propose to integrate new chains</span>
          </div>
          <dl class="dropx-common font-medium" v-if="commonChainList.length > 0">
            <dt>Common</dt>
            <dd>
              <a-row type="flex" :gutter="[10, 16]">
                <a-col v-for="_chainId in commonChainList" :key="_chainId" @click="switchNetwork(_chainId)">
                  <div class="network-block" :class="{ 'network-active': chainId == _chainId }">
                    <img :src="chainId == _chainId ? `/img/${networkObj[_chainId]['nativeCurrency']['name']}-co.svg` : `/img/${networkObj[_chainId]['nativeCurrency']['name']}.svg`" />{{
                      networkObj[_chainId]['chainName']
                    }}
                  </div>
                </a-col>
              </a-row>
            </dd>
          </dl>
          <dl class="dropx-networks">
            <dd>
              <a-row type="flex" :gutter="[10, 16]">
                <a-col v-for="(item, index) in networkObj" :key="index" @click="switchNetwork(item.chainId)">
                  <div class="network-block" style="cursor: pointer" :class="{ 'network-disabled': item.disabled, 'network-active': item.chainId == chainId }">
                    <img :src="item.chainId == chainId ? `/img/${item.nativeCurrency.name}-co.svg` : `/img/${item.nativeCurrency.name}.svg`" class="mg-r8" />
                    {{ item.chainName }}
                  </div>
                </a-col>
              </a-row>
            </dd>
          </dl>
        </div>
        <div class="dropx-container">
          <div class="dropx-container-info">
            <div class="label-container">
              <label class="font-bold">Tokens</label>
              <label class="font-medium"
                >Balance:
                <span style="color: #dbc253"> {{ selectedTokenBalance | decimalsPrecision(selectedTokenDecimals, 4) }} {{ selectedTokenSymbol }} </span>
              </label>
            </div>
            <div class="dropx-input-search">
              <a-dropdown>
                <a-input-search v-model="searchValue" placeholder="Select Your Token" @input="searchFilter" @keyup.enter="searchReturn" :loading="searchLoading"></a-input-search>
                <a-menu slot="overlay">
                  <a-menu-item v-for="(item, index) in filterTokenList" :key="index" @click="searchListClick(item)">
                    <a rel="noopener noreferrer">{{ item.name }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <p class="input-search-note">*ERC-20 tokens compatible</p>
            </div>
            <div class="label-container-amount">
              <label>Fixed amount (Please ignore when the amount is different)</label>
              <a-switch class="mg-l8" @change="amountChange" />
            </div>
            <div class="dropx-input-number">
              <a-input-number :min="0" v-model="dropxAmount" :placeholder="amountDisabled ? '--' : '0.0000'" :precision="4" style="width: 100%" :disabled="amountDisabled" />
            </div>
            <div class="label-container label-container-csv">
              <label class="font-bolder">Fill in the address information</label>
            </div>
            <div class="cm-container">
              <codemirror ref="cmEditor" :value="code" :options="cmOptions" @input="onCmCodeChange" @focus="onCmFocus"></codemirror>
              <div class="btn-container">
                <a-upload :before-upload="beforeUpload" accept=".csv">
                  <a-button type="primary" size="large">Choose File</a-button>
                </a-upload>
                <!--                <div @click="fullscreen" v-if="!isMobile">-->
                <!--                  <img src="~@/assets/images/fullscreen.svg"/>-->
                <!--                </div>-->
              </div>
              <div v-if="this.cmOptions.fullScreen" class="is-fullscreen" @click="fullscreen">
                <img src="~@/assets/images/fullscreen.svg" />
              </div>
            </div>
            <div class="btn-proceed" @click="proceed">
              <a-button type="primary" size="large" :loading="connectedLoading"> Proceed </a-button>
            </div>
            <div class="address-container">
              <label class="font-bold"> Drop to Earn Explanation </label>
              <p>Hold DRPX, DropX's governance token, to influence platform decisions and reap the benefits of the increasing token value.</p>
              <p>When you use DropX to airdrop tokens to your followers, a small GAS fee is required. Notably, 60% of this fee is automatically converted into DRPX on the DEX and returned to you.</p>
              <p>DRPX tokens will play a crucial role in the continuous rollout of upcoming products.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dropx-approve" v-if="step == 2">
        <div class="dropx-approve-info">
          <a-row>
            <a-col :span="12">
              <label>Total Number Of Addresses</label>
              <div>{{ addressList.length }}</div>
            </a-col>
            <a-col :span="12">
              <label>Total Number Of Tokens To Be Sent</label>
              <div>{{ totalToken | decimalsPrecision(selectedTokenDecimals, 4) }} {{ selectedTokenSymbol }}</div>
            </a-col>
            <a-col :span="12">
              <label>Total Gas Fee</label>
              <div :class="{ 'gas-red': notEnoughEth }">{{ totalGas | decimalsPrecision(18, 4) }} {{ baseSymbol }}</div>
            </a-col>
            <a-col :span="12">
              <label>{{ baseSymbol }} Balance</label>
              <div :class="{ 'gas-red': notEnoughEth }">{{ ethBalance | decimalsPrecision(18, 4) }} {{ baseSymbol }}</div>
            </a-col>
          </a-row>
          <div class="claim-table-title">List Of Recipients</div>
          <claim-table v-if="addressList.length > 0" :list="addressList" :status="sendSuccess"></claim-table>
          <div class="btn-Approve" v-if="!sendSuccess">
            <a-button v-if="!isApproved" @click="clickApprove" type="primary" size="large" :loading="connectedLoading"> Approve </a-button>
            <a-button v-else type="primary" size="large" @click="send" :loading="connectedLoading"> DropX </a-button>
          </div>
          <div class="address-container hash-container" v-else>
            <label class="font-bold">Hash</label>
            <p>
              <a :href="explorerUrl" target="_blank">{{ successHash }}</a>
            </p>
          </div>
          <div class="address-container">
            <label class="font-bold">DropX Contract</label>
            <p>
              <span>{{ multiSender }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <p>DropX © 2024</p>
      <p>Donation Address: <a target="_blank" href="https://www.maposcan.io/address/0xa85514eaebb42609a582b7b1844f2130bd781523">0xa85514eaebb42609a582b7b1844f2130bd781523</a></p>
    </footer>
  </div>
</template>

<script>
import wallet from './wallet';
import claimTable from './table';
import { getTokenList, getMapoTokenList } from '@/api';
import { multiSender, MaxUint256, Network } from '@/config/constants';
import { mapState, mapGetters, mapActions } from 'vuex';
import { codemirror } from 'vue-codemirror';
import { ethers, constants } from 'ethers';
import BigNumber from 'bignumber.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/addon/display/fullscreen.js';
import METAERC20 from '@/abi/ERC20.json';
import METAMUTISENDER from '@/abi/mutiSender.json';
import { getStorage, setStorage } from '@/utils';

export default {
  name: 'Dropx',
  components: {
    wallet,
    codemirror,
    claimTable,
  },
  data() {
    return {
      networkObj: Network,
      cmOptions: {
        mode: {
          name: 'javascript',
          json: true,
        },
        theme: 'rubyblue',
        lineNumbers: true,
        line: true,
        fullScreen: false,
      },
      code: '',
      searchValue: '',
      tokenAddress: '',
      dropxAmount: null,
      addressList: [],
      step: 1,
      connectedLoading: false,
      searchLoading: false,
      transactionFee: 0,
      tokenList: [],
      filterTokenList: [],
      totalToken: 0,
      sendObj: {},
      sendSuccess: false,
      csvExample: 'Example:0x999999360C8448619a1E170371beA75f869211E8,5999',
      amountDisabled: true,
      commonChainList: [],
      successHash: null,
      totalGas: '0',
      selectedTokenAddress: null,
      selectedTokenBalance: 0,
      selectedTokenDecimals: 0,
      selectedTokenSymbol: '',
      selectedTokenNative: false,
      ethBalance: 0,
      erc20Contract: null,
      allowance: 0,
      multiSender,
      gasPrice: 0,
      platformFee: '0',
    };
  },
  computed: {
    ...mapGetters(['baseSymbol']),
    ...mapState({
      isMobile: (state) => state.isMobile,
      account: (state) => state.account,
      chainId: (state) => state.chainId,
      desireChainId: (state) => state.desireChainId,
    }),
    isApproved() {
      if (this.selectedTokenAddress == constants.AddressZero) {
        return true;
      } else {
        return BigNumber(this.allowance).gte(this.totalToken);
      }
    },
    notEnoughEth() {
      return BigNumber(this.transactionFee).plus(this.sendObj.ethSendValue).gte(this.ethBalance);
    },
    explorerUrl() {
      return this.networkObj[this.chainId].blockExplorerUrls[0] + '/tx/' + this.successHash;
    },
  },
  created() {
    this.decideMobile();
    this.code = this.csvExample;
    let _commonChain = getStorage('commonChainList');
    if (_commonChain !== '' && _commonChain !== null) {
      let _commonChainList = _commonChain.split(',');
      let commonChainList = [];
      for (let i = 0; i < _commonChainList.length; i++) {
        if (Network[_commonChainList[i]]) {
          commonChainList.push(_commonChainList[i]);
        }
      }
      this.commonChainList = commonChainList;
    }
  },
  watch: {
    account: {
      immediate: true,
      handler: function (val) {
        if (this.chainId != this.desireChainId) {
          return;
        }
        this.accountChange(val);
      },
    },
  },
  mounted() {
    this.$refs.cmEditor.codemirror.on('blur', () => {
      if (!this.code) {
        this.code = this.csvExample;
      }
    });
  },
  methods: {
    ...mapActions(['switchNetwork']),
    accountChange(userAddress) {
      this.$store.state.provider.getGasPrice().then((gasPrice) => {
        this.gasPrice = gasPrice.toString();
      });
      this.multiSenderContract = new ethers.Contract(multiSender, METAMUTISENDER, this.$store.state.signer);
      this.searchLoading = true;
      console.log(this.chainId);
      if (this.chainId == '0x58f8') {
        getMapoTokenList(userAddress)
          .then(async (res) => {
            let items = res.data.list;
            let newList = [];
            const ethBalance = await this.$store.state.provider.getBalance(userAddress);
            this.ethBalance = ethBalance.toString();
            newList.push({
              contract_ticker_symbol: 'MAPO',
              contract_address: ethers.constants.AddressZero,
              contract_decimals: 18,
              balance: ethBalance.toString(),
              native_token: 'MAPO',
            });
            for (let i = 0; i < items.length; i++) {
              if (items[i]['tokenBalance'] == 0) {
                continue;
              }
              newList.push({
                contract_ticker_symbol: items[i]['symbol'],
                contract_address: items[i]['contract'],
                contract_decimals: items[i]['decimal'],
                balance: items[i]['tokenBalance'],
              });
            }
            this.handleList(userAddress, newList);
          })
          .catch(() => {
            this.accountCatch(userAddress);
          });
      } else {
        getTokenList(userAddress, parseInt(this.chainId, 16))
          .then((res) => {
            let items = res.data.items;
            this.handleList(userAddress, items);
          })
          .catch(() => {
            this.accountCatch(userAddress);
          });
      }
    },
    handleList(userAddress, items) {
      this.$store.state.provider.getBalance(userAddress).then((ethBalance) => {
        this.ethBalance = ethBalance.toString();
      });
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        const balance = BigNumber(item.balance).dividedBy(BigNumber(10).pow(item.contract_decimals)).toFixed(4);
        let name = '';
        if (item.native_token) {
          item.contract_address = constants.AddressZero;
        }
        if (item.contract_address === constants.AddressZero) {
          name = item.contract_ticker_symbol + ' - ' + balance;
        } else {
          name = item.contract_ticker_symbol + ' - ' + balance + ' - ' + item.contract_address;
        }
        items[i].name = name;
      }
      this.tokenList = items;
      this.searchValue = '';
      this.searchLoading = false;
      this.filterTokenList = items;
    },
    accountCatch(userAddress) {
      this.$store.state.provider.getBalance(userAddress).then((ethBalance) => {
        this.ethBalance = ethBalance.toString();
        let item = {};
        const balance = BigNumber(ethBalance.toString()).dividedBy(BigNumber(10).pow(18)).toFixed(4);
        item.contract_address = constants.AddressZero;
        item.name = Network[this.chainId].nativeCurrency.symbol + ' - ' + balance;
        this.tokenList = [item];
        this.searchValue = '';
        this.searchLoading = false;
        this.filterTokenList = [item];
      });
    },
    async callTokenInfo(contract_address) {
      try {
        if (!this.account) {
          return;
        }
        contract_address = contract_address.toLowerCase();
        if (contract_address === constants.AddressZero) {
          let _balance = await this.$store.state.provider.getBalance(this.account);
          let searchValue = this.baseSymbol + ' - ' + BigNumber(_balance.toString()).div(1e18).toFixed(4);
          this.searchValue = searchValue;
          this.selectedTokenBalance = _balance.toString();
          this.selectedTokenDecimals = 18;
          this.selectedTokenSymbol = this.baseSymbol;
          return;
        }
        let erc20Contract = new ethers.Contract(contract_address, METAERC20, this.$store.state.signer);
        this.erc20Contract = erc20Contract;
        let balance = await erc20Contract.balanceOf(this.account);
        let decimals = await erc20Contract.decimals();
        let allowance = await erc20Contract.allowance(this.account, multiSender);
        this.allowance = allowance.toString();
        let symbol = await erc20Contract.symbol();
        this.selectedTokenBalance = balance.toString();
        balance = BigNumber(balance.toString()).dividedBy(BigNumber(10).pow(decimals)).toFixed(4);
        let searchValue = symbol + ' - ' + balance + ' - ' + contract_address;
        this.searchValue = searchValue;
        this.selectedTokenDecimals = decimals;
        this.selectedTokenSymbol = symbol;
      } catch (e) {
        console.log(e);
        this.selectedTokenBalance = 0;
        this.selectedTokenSymbol = '-';
        this.selectedTokenDecimals = 0;
        this.searchValue = '';
      }
    },
    clickApprove() {
      this.connectedLoading = true;
      this.erc20Contract
        .approve(multiSender, MaxUint256)
        .then(async (tx) => {
          await this.$store.state.provider.waitForTransaction(tx.hash);
          this.allowance = MaxUint256.toString();
          this.estimateGas();
          this.connectedLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.connectedLoading = false;
          this.$notification['error']({
            message: 'Transaction Failed',
          });
        });
    },
    async proceed() {
      if (!this.account) {
        await this.$store.dispatch('connected');
        return;
      }
      if (this.searchValue === '' || this.selectedTokenAddress == null || this.selectedTokenAddress == '') {
        this.$notification['error']({
          message: 'Please input valid Tokens',
        });
        return;
      }
      if (this.code == this.csvExample) {
        this.$notification['error']({
          message: 'Please input CSV',
        });
        return;
      }
      try {
        this.connectedLoading = true;
        const rows = this.code.split('\n');
        this.addressList = [];
        let totalToken = BigNumber(0);
        this.transactionFee = 0;
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]) {
            let pattern = /^(0x)[0-9a-fA-F]{40},\d+(\.\d+)?$/;
            if (this.dropxAmount > 0) {
              pattern = /^(0x)[0-9a-fA-F]{40}(,\d+(\.\d+)?)?$/;
            }
            if (!pattern.test(rows[i])) {
              this.$notification['error']({
                message: 'Line' + (i + 1) + 'wrong address, Please re-enter your address.',
              });
              this.connectedLoading = false;
              return;
            }
            let params = {};
            if (this.dropxAmount > 0) {
              let item = rows[i];
              if (rows[i].indexOf(',') > 0) {
                item = rows[i].split(',')[0];
              }
              params = {
                address: item,
                amount: this.dropxAmount,
              };
            } else {
              const item = rows[i].split(',');
              params = {
                address: item[0],
                amount: item[1],
              };
            }
            this.addressList.push(params);
            totalToken = totalToken.plus(params.amount);
          }
        }
        totalToken = totalToken.multipliedBy(BigNumber(10).pow(this.selectedTokenDecimals)).toFixed(0);
        this.totalToken = totalToken;
        if (!BigNumber(this.selectedTokenBalance).gte(totalToken)) {
          this.$notification['error']({
            message: 'Insufficient wallet balance',
          });
          this.connectedLoading = false;
          return;
        }
        const address = [];
        const amount = [];
        const fee = await this.multiSenderContract.fee();
        const pow = new BigNumber(10).pow(this.selectedTokenDecimals).toFixed(0);
        this.addressList.forEach((item) => {
          address.push(item.address);
          amount.push(BigNumber(item.amount).multipliedBy(pow).toFixed());
        });
        let ethSendValue = BigNumber(fee.toString());
        this.platformFee = fee.toString();
        if (this.selectedTokenAddress == constants.AddressZero) {
          ethSendValue = ethSendValue.plus(totalToken);
        }
        this.sendObj = {
          address: address,
          amount: amount,
          ethSendValue,
        };
        this.step = 2;
        this.initPopstate();
      } catch (err) {
        console.log(err);
        this.$notification['error']({
          message: err.data.message,
        });
      }
      this.connectedLoading = false;
      this.estimateGas();
    },
    initPopstate() {
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.goBack, false);
      }
    },
    async estimateGas() {
      try {
        if (!this.isApproved) {
          return;
        }
        const options = { value: this.sendObj.ethSendValue.toFixed(0) };
        let gasLimit;
        if (this.selectedTokenAddress == constants.AddressZero) {
          gasLimit = await this.multiSenderContract.estimateGas.sendEth(this.sendObj.address, this.sendObj.amount, options);
        } else {
          gasLimit = await this.multiSenderContract.estimateGas.send(this.selectedTokenAddress, this.sendObj.address, this.sendObj.amount, this.totalToken, options);
        }
        this.transactionFee = BigNumber(this.gasPrice).multipliedBy(gasLimit.toString()).toString();
        this.totalGas = BigNumber(this.platformFee).plus(this.transactionFee).toString();
      } catch (err) {
        console.log(err);
        this.$notification['error']({
          message: err.data.message,
        });
      }
    },
    send() {
      const fullGas = BigNumber(this.ethBalance).gt(BigNumber.sum(this.transactionFee, this.sendObj.ethSendValue));
      if (!fullGas) {
        this.$notification['error']({
          message: 'Insufficient Gas',
        });
        return;
      }
      this.connectedLoading = true;
      this.sendSuccess = false;
      const options = { value: this.sendObj.ethSendValue.toFixed() };
      const proccessTx = async (tx) => {
        let receipt = await this.$store.state.provider.waitForTransaction(tx.hash);
        if (receipt.status != 0) {
          this.$notification['success']({
            message: 'Transaction Successed',
          });
          this.sendSuccess = true;
        } else {
          this.$notification['error']({
            message: 'Transaction Failed',
          });
        }
        let commonChainList = this.commonChainList;
        if (commonChainList.includes(this.chainId)) {
          let index = commonChainList.findIndex((val) => val == this.chainId);
          if (index !== 0) {
            commonChainList.splice(index, 1);
            setStorage('commonChainList', commonChainList.join(','));
            this.commonChainList = commonChainList;
          }
        } else {
          commonChainList.unshift(this.chainId);
          setStorage('commonChainList', commonChainList.join(','));
          this.commonChainList = commonChainList;
        }
        this.successHash = tx.hash;
        this.connectedLoading = false;
      };
      const processError = (err) => {
        this.connectedLoading = false;
        console.log(err);
        this.$notification['error']({
          message: 'Transaction Failed',
        });
      };
      if (this.selectedTokenAddress == constants.AddressZero) {
        console.log(this.sendObj.address, this.sendObj.amount, options);
        this.multiSenderContract.sendEth(this.sendObj.address, this.sendObj.amount, options).then(proccessTx).catch(processError);
      } else {
        this.multiSenderContract.send(this.selectedTokenAddress, this.sendObj.address, this.sendObj.amount, this.totalToken, options).then(proccessTx).catch(processError);
      }
    },
    searchFilter() {
      let list = this.tokenList;
      if (this.searchValue) {
        list = list.filter((item) => {
          return item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0;
        });
      }
      this.filterTokenList = list;
    },
    async searchReturn(e) {
      if (e.keyCode !== 13) {
        return;
      }
      const contract_address = this.searchValue;
      if (/^(0x)[0-9a-fA-F]{40}$/.test(contract_address)) {
        const code = await this.$store.state.provider.getCode(contract_address);
        if (code === '0x') {
          this.$notification['error']({
            message: 'Please input valid Tokens',
          });
          return;
        }
        this.selectedTokenAddress = contract_address;
        this.callTokenInfo(contract_address);
      } else {
        if (this.filterTokenList.length > 0) {
          return;
        }
        this.$notification['error']({
          message: 'Please input valid Tokens',
        });
      }
    },
    searchListClick(item) {
      this.searchValue = item.name;
      this.selectedTokenAddress = item.contract_address;
      this.selectedTokenBalance = 0;
      this.selectedTokenSymbol = '';
      this.selectedTokenDecimals = 0;
      this.callTokenInfo(item.contract_address);
    },
    beforeUpload(file) {
      const reader = new FileReader();
      reader.readAsText(file, 'utf-8');
      reader.onload = () => {
        const str = reader.result;
        //const rows = str.split('\r\n');
        this.code = str;
      };
      return false;
    },
    fullscreen() {
      if (this.cmOptions.fullScreen) {
        this.cmOptions.fullScreen = false;
      } else {
        this.cmOptions.fullScreen = true;
      }
    },
    onCmCodeChange(newCode) {
      this.code = newCode;
    },
    onCmFocus() {
      if (this.code == this.csvExample) {
        this.code = '';
      }
    },
    decideMobile() {
      if (/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(navigator.userAgent)) {
        this.$store.dispatch('updateMobile', true);
      }
    },
    goBack() {
      this.step = 1;
    },
    amountChange(checked) {
      this.amountDisabled = !checked;
    },
  },
};
</script>
<style>
.ant-input-affix-wrapper .ant-input-suffix .anticon-search {
  display: none;
}

.cm-s-rubyblue.CodeMirror {
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 10px;
  border: 1px solid #dbc253;
  z-index: 1;
  transition: box-shadow 0.3s;
  line-height: 2;
  font-family: Menlo, Monaco, Courier New, monospace;
}

.cm-s-rubyblue .CodeMirror-gutters {
  background: rgba(255, 255, 255, 0);
  border-right: 1px solid rgba(219, 194, 83, 0.5);
}

.cm-s-rubyblue .CodeMirror-linenumber {
  color: #fff;
  min-width: 2.9em;
  font-weight: bold;
  text-align: center;
}

.cm-s-rubyblue.CodeMirror-fullscreen {
  position: fixed;
  top: 100px;
  right: 1em;
  bottom: 1em;
  left: 1em;
  z-index: 39;
  margin: 0;
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding-left: 22px;
}
</style>
<style scoped lang="scss">
.is-fullscreen {
  position: fixed;
  top: 120px;
  right: 40px;
  z-index: 40;
}

.network-block {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);

  &.network-active {
    img {
      filter: invert(80%);
    }
  }
  img {
    filter: grayscale(100%);
  }
}

.btn-container {
  display: flex;
  align-items: center;
  margin-top: 20px;

  button {
    border: none;
    width: 150px;
    height: 40px;
    background: rgba(219, 194, 83, 1);
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Manrope-Medium';
    font-weight: bold;
    color: #333;
    margin-right: 22px;

    &:hover {
      box-shadow: 0px 0px 10px #dbc253ff;
    }
  }

  /deep/ .ant-upload-list {
    display: none;
  }
}

.dropx-input-search {
  /deep/ .ant-input-search {
    &:hover {
      input {
        border: 1px solid rgba(219, 194, 83, 0.5);
      }
    }

    input {
      height: 42px;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid rgba(219, 194, 83, 0.5);
      background: transparent;
      color: #ffffff;
      padding-left: 32px;

      &::placeholder {
        color: #dbc253;
      }
    }

    .ant-input-search-icon {
      color: #dbc253;
    }

    .ant-input-suffix {
      right: 32px;
    }
  }

  .input-search-note {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #dbc253;
    margin-top: 20px;
  }
}

.dropx-input-number {
  /deep/ .ant-input-number {
    height: 42px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #ffffff;

    .ant-input-number-handler-wrap {
      display: none;
    }

    input {
      padding-left: 32px;
      height: 42px;
    }

    &.ant-input-number-disabled {
      opacity: 0.2;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.dropx-content {
  header {
    .header {
      margin: 0 auto;
      width: 80%;
      min-width: 1200px;
      display: flex;
      justify-content: space-between;
    }

    .dropx-logo {
      padding: 22px 0 32px;
      cursor: pointer;
    }
  }

  .btn-proceed {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    button {
      width: 100%;
      height: 52px;
      background: rgba(219, 194, 83, 1);
      //box-shadow: 3px 3px 26px 0px rgba(0, 197, 189, 0.4);
      border-radius: 10px;
      //border: 1px solid #00FFBC;
      color: #333;
      font-family: Roboto, Roboto;
      font-weight: bold;

      &:hover {
        box-shadow: 0px 0px 10px #dbc253ff;
      }
    }
  }

  .btn-Approve {
    display: flex;
    justify-content: center;
    padding-top: 16px;

    button {
      width: 100%;
      height: 52px;
      background: rgba(219, 194, 83, 0.8);
      //box-shadow: 3px 3px 26px 0px rgba(0, 197, 189, 0.4);
      border-radius: 10px;
      //border: 1px solid #00FFBC;

      &:hover,
      &:focus,
      &:active {
        background: #dbc253;
      }

      &.back-button {
        background: none;
        color: #dbc253;
        margin-right: 12px;
      }
    }

    button[disabled] {
      color: rgba(0, 0, 0, 0.3);
      background-color: #eeeeee;
      border-color: #d9d9d9;
    }
  }

  .address-container {
    label {
      display: block;
      padding: 30px 0 8px;
      font-weight: bold;
      font-size: 20px;
    }

    p {
      font-weight: 500;
      font-family: Roboto, Roboto;
      font-size: 14px;
      margin-top: 10px;
      color: rgba(255, 255, 255, 0.5);
    }

    &.hash-container {
      p {
        border-color: #ffffff;

        a {
          color: #ffffff;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }

  .dropx-box {
    position: relative;
    max-width: 1152px;
    padding: 48px 20px;
    margin: 0 auto;

    .dropx-approve {
      max-width: 680px;
      margin: 58px auto 0;
      position: relative;

      &:before {
        content: '';
        background: rgba(0, 197, 189, 0.4);
        border-radius: 10px;
        filter: blur(50px);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
      }

      &-info {
        padding: 36px;
        background: #171b2b;
        border-radius: 20px;
        border: 1px solid #05628f;
        position: relative;
        z-index: 2;

        .gas-red {
          color: #db6868;
        }
      }

      .ant-row {
        font-size: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        label {
          display: block;
          font-size: 14px;
          font-family: 'Manrope-Medium';
          color: rgba(255, 255, 255, 0.3);
          line-height: 20px;
          padding-bottom: 16px;
        }

        label + div {
          padding-bottom: 26px;
        }
      }

      .claim-table-title {
        font-size: 14px;
        font-family: 'Manrope-Bold';
        color: #ffffff;
        line-height: 20px;
        padding: 24px 0 16px;
      }
    }

    .dropx-main {
      max-width: 680px;
      margin: 0 auto;

      .dropx-title {
        margin-bottom: 24px;
        color: #ffffff;
        font-size: 20px;
        display: flex;
        align-items: flex-end;

        span {
          margin-left: 10px;
          font-size: 14px;
        }
      }

      .dropx-common {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(219, 194, 83, 1);

        dt {
          color: rgba(255, 255, 255, 0.3);
          padding-bottom: 16px;
          font-size: 12px;
        }

        dd {
          .network-block {
            &.network-active {
              opacity: 1;
              color: #333333;
              background: rgba(219, 194, 83, 1);
              border: none;
            }

            width: 160px;
            height: 52px;
            padding: 0 16px;
            background: rgba(219, 194, 83, 1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 18px;

            img {
              width: 16px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }

      .dropx-networks {
        dt {
          color: #333333;
          padding-top: 12px;
          font-size: 12px;
        }

        dd {
          padding-top: 8px;

          .network-block {
            &.network-active {
              opacity: 1;
              color: #333333;
              background: rgba(219, 194, 83, 1);
              border: none;
            }

            width: 160px;
            height: 52px;
            padding: 0 16px;
            border-radius: 10px;
            border: 1px solid #ffffff;
            display: flex;
            align-items: center;
            opacity: 0.5;
            font-size: 14px;
            line-height: 18px;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }

      .dropx-container {
        margin-top: 56px;
        position: relative;

        &:before {
          content: '';
          border-radius: 10px;
          filter: blur(50px);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
        }

        &-info {
          background: #131f2d;
          padding: 36px;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.15);
          position: relative;
          z-index: 2;
        }
      }

      .label-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        label {
          font-size: 14px;
        }

        &.label-container-csv {
          padding-top: 32px;
        }
      }

      .label-container-amount {
        padding: 26px 0 8px;
        font-weight: bold;
        color: #ffffff;
        font-size: 12px;

        button {
          width: 40px;
          height: 20px;
          border-radius: 10px;
          opacity: 0.8;
          border: 1px solid #979797;

          &:after {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background: #ccced0;
          }

          &.ant-switch-checked {
            border-color: #dbc253;
            background-color: rgba(3, 122, 126, 0.3);

            &:after {
              background: #dbc253;
            }
          }
        }
      }

      .cm-container {
        min-height: 200px;

        &.is-relative {
          position: relative;
        }

        .vue-codemirror {
          width: 100%;
        }
      }
    }
  }

  footer {
    margin-top: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0 30px;

    p {
      text-align: center;
      font-size: 14px;
      color: #c1c7cb;
      line-height: 20px;
      padding-bottom: 10px;
      a {
        color: #c1c7cb;
        &:hover {
          color: #dbc253;
        }
      }
    }
  }
}

.dropx-mobile {
  header {
    padding: 0.28rem 0.4rem 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stake-logo {
      img {
        width: 2.8rem;
        height: 0.4rem;
      }
    }

    /deep/ .ant-btn {
      width: 2.7rem;
      height: 0.72rem;
      font-size: 0.28rem;
      padding: 0;
      border-radius: 0.36rem;
    }

    /deep/ .change-network {
      width: 0.72rem;
      height: 0.72rem;
      margin-right: 0.16rem;
      border-radius: 0.36rem;

      .change-network-img {
        width: 0.24rem;
      }

      .ant-select-selection--single {
        height: 0.72rem;
        border-radius: 0.36rem;

        .ant-select-selection__rendered {
          height: 0.72rem;
          margin: 0;
          line-height: normal;
          align-items: center;
          display: flex;
          justify-content: center;

          span {
            display: none;
          }
        }

        .ant-select-arrow {
          display: none;
        }
      }
    }

    .dropx-logo {
      padding: 0;

      img {
        width: 2.44rem;
      }
    }
  }

  .btn-proceed {
    padding-top: 0.62rem;

    button {
      height: 1.04rem;
      border-radius: 0.52rem;
      font-size: 0.32rem;
    }
  }

  .btn-Approve {
    padding-top: 0.48rem;

    button {
      height: 1.04rem;
      border-radius: 0.52rem;
      font-size: 0.32rem;
    }

    button[disabled] {
      color: rgba(0, 0, 0, 0.3);
      background-color: #eeeeee;
      border-color: #d9d9d9;
    }
  }

  .btn-container {
    margin-top: 0.4rem;

    button {
      width: 2.5rem;
      height: 0.76rem;
      border-radius: 0.38rem;
      font-size: 0.28rem;
      margin-right: 0;
    }

    /deep/ .ant-upload-list {
      display: none;
    }
  }

  .dropx-input-search {
    /deep/ .ant-input-search {
      input {
        height: 0.84rem;
        border-radius: 0.42rem;
        padding-left: 0.4rem;
        font-size: 0.28rem;
      }

      .ant-input-suffix {
        right: 0.4rem;
      }
    }

    .input-search-note {
      font-size: 0.24rem;
      line-height: 0.32rem;
      margin-top: 0.16rem;
    }
  }

  .dropx-input-number {
    /deep/ .ant-input-number {
      height: 0.84rem;
      border-radius: 0.42rem;

      input {
        padding-left: 0.4rem;
        height: 0.84rem;
        font-size: 0.28rem;
      }
    }
  }

  .address-container {
    label {
      font-size: 0.28rem;
      padding: 0.6rem 0 0.16rem;
    }

    p {
      width: 100%;
      padding: 0.2rem;
      border-radius: 0.54rem;

      span {
        font-size: 0.24rem;
        word-break: break-all;
        line-height: 0.36rem;
      }
    }

    &.hash-container {
      p {
        a {
          font-size: 0.24rem;
        }
      }
    }
  }

  .dropx-box {
    padding: 0.4rem 0.4rem;

    .dropx-main {
      .dropx-title {
        font-size: 0.28rem;
        margin-bottom: 0.24rem;

        span {
          margin-left: 10px;
          font-weight: normal !important;
        }
      }

      .dropx-common {
        padding-bottom: 0.24rem;

        dt {
          padding-bottom: 0.16rem;
          font-size: 0.24rem;
        }

        dd {
          .network-block {
            &.network-active {
              opacity: 1;
              color: #333333;
              background: rgba(219, 194, 83, 1);
              border: none;
            }

            width: 3.2rem;
            height: 1.04rem;
            padding: 0 0.32rem;
            border-radius: 0.32rem;
            font-size: 0.28rem;
            line-height: 0.36rem;

            img {
              width: 0.32rem;
              height: 0.48rem;
              margin-right: 0.16rem;
            }
          }
        }
      }

      .dropx-networks {
        dt {
          padding-top: 0.24rem;
          font-size: 0.24rem;
        }

        dd {
          padding-top: 0.16rem;

          .network-block {
            &.network-active {
              opacity: 1;
              color: #333333;
              background: rgba(219, 194, 83, 1);
              border: none;
            }

            width: 3.2rem;
            height: 1.04rem;
            padding: 0 0.32rem;
            border-radius: 0.32rem;
            font-size: 0.28rem;
            line-height: 0.36rem;

            img {
              width: 0.32rem;
              height: 0.48rem;
              margin-right: 0.16rem;
            }
          }
        }
      }

      .dropx-container {
        margin-top: 0.76rem;

        &-info {
          padding: 0.48rem 0.24rem 0.68rem;
          border-radius: 0.4rem;
        }
      }

      .label-container {
        padding-bottom: 0.28rem;

        label {
          font-size: 0.28rem;
        }

        &.label-container-csv {
          padding-top: 0.6rem;
          padding-bottom: 0.32rem;
        }
      }

      .label-container-amount {
        padding: 0.48rem 0 0.16rem;
        font-size: 0.24rem;

        button {
          width: 0.72rem;
          height: 0.36rem;
          border-radius: 0.2rem;

          &:after {
            width: 0.28rem;
            height: 0.28rem;
            border-radius: 0.28rem;
            top: 0.03rem;
          }
        }
      }

      .cm-container {
        border-radius: 0.2rem;

        .vue-codemirror {
          width: 100%;

          /deep/ .cm-s-rubyblue {
            border-radius: 0.2rem;

            pre.CodeMirror-line {
              padding-left: 0.2rem;

              span {
                word-break: break-all;
              }
            }

            .CodeMirror-linenumber {
              padding: 0;
              min-width: 0.6rem;
              width: 0.6rem !important;
            }
          }
        }
      }
    }

    .dropx-approve {
      margin: 0.4rem auto;
      position: relative;

      &-info {
        padding: 0.48rem 0.24rem 0.68rem;
        border-radius: 0.4rem;
      }

      .ant-row {
        font-size: 0.32rem;

        label {
          display: block;
          font-size: 0.28rem;
          font-family: 'Manrope-Medium';
          color: rgba(255, 255, 255, 0.3);
          line-height: 0.38rem;
          padding-bottom: 0.16rem;
        }

        label + div {
          padding-bottom: 0.36rem;
        }
      }

      .claim-table-title {
        font-size: 0.28rem;
        line-height: 0.38rem;
        padding: 0.56rem 0 0.34rem;
      }
    }
  }

  footer {
    margin-top: 0.4rem;

    p {
      font-size: 0.28rem;
      line-height: 0.38rem;
      padding: 0.56rem 0;
    }
  }
}
</style>
