import request from '../utils/request';

export function getTokenList(address, chainId) {
  return request({
    url: 'https://api.covalenthq.com/v1/' + chainId + '/address/' + address + '/balances_v2/?no-nft-fetch=true&key=ckey_295ada0db0ad43f1bce3e3b443d',
    method: 'get',
    direct: 1,
  });
}

export function getMapoTokenList(address) {
  return request({
    url: 'https://ms-mainnet-api.chainservice.io/scan/api/queryErc20TokenList?address=' + address + '&page=1&size=100',
    method: 'get',
    direct: 1,
  });
}

export function transStatus(data) {
  return request({
    url: 'get-trans-status',
    method: 'get',
    direct: 1,
    params: data,
  });
}

export function rewardList(data) {
  return request({
    url: 'reward-record',
    method: 'get',
    direct: 1,
    params: data,
  });
}

export function balance(data) {
  return request({
    url: 'balance',
    method: 'get',
    direct: 1,
    params: data,
  });
}
