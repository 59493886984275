<!--  -->
<template>
  <div class="flex-box">
    <a-select
        class="change-network"
        :dropdownClassName="isMobile?'drop-network':''"
        :value="desireChainId"
        @change="switchNetwork"

    >
      <a-select-option
          v-for="item in networkObj"
          :key="item.chainId"
          :value="item.chainId"
          :disabled="item.disabled"
      >
        <div class="flex-box">
          <img
              width="24px"
              :src="`/img/${item.nativeCurrency.name}.svg`"
              class="change-network-img"
          />
          <span class="network-span">
                        {{ item.chainName }}
                    </span>
        </div>
      </a-select-option>
    </a-select>
    <template v-if="account">
      <template v-if="desireChainId != chainId">
        <a-button
            @click="switchNetwork(desireChainId)"
            :loading="connectedLoading"
        >
          Wrong Network
        </a-button>
      </template>
      <template v-else>
        <a-popover placement="bottomRight">
          <a-button style="display: flex;align-items: center" :loading="connectedLoading" class="active-button">
            <img width="16" height="16" style="margin-right: 10px" src="../assets/images/wallet_icon.png" alt="">
            {{ account | showAddress }}
          </a-button>
          <template slot="content">
            <p
                @click="disConnect"
                class="color-0E1D51 ts-16 cursor"
            >
              <a-icon type="disconnect"/>
              <span class="pd-l8"
              >Disconnect wallet</span
              >
            </p>
          </template>
        </a-popover>
      </template>
    </template>
    <a-button
        class="connection"
        v-else
        @click="onConnected"
        :loading="connectedLoading"
        style="display: flex;align-items: center"
    >
      <img width="16" height="16" style="margin-right: 10px" src="../assets/images/wallet_icon.png" alt="">
      Connect Wallet
    </a-button>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {message} from "ant-design-vue";
import {Network} from "@/config/constants";
import detectEthereumProvider from "@metamask/detect-provider";

export default {
  components: {},
  data() {
    return {
      connectedLoading: false,
      networkObj: Network,
    };
  },
  filters: {
    showAddress: function (text) {
      return text.substring(0, 6) + "..." + text.substring(38, 42);
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      isMobile: (state) => state.isMobile,
      chainId: (state) => state.chainId,
      desireChainId: (state) => state.desireChainId,
    }),
  },
  watch: {
    mobile(val) {
      this.mobile = val;
    },
  },
  methods: {
    ...mapActions(["connected", "updateAccount", "switchNetwork"]),
    disConnect() {
      this.updateAccount(null);
    },
    onConnected() {
      if (!(typeof window.ethereum !== "undefined")) {
        message.error("install MetaMask first!");
      } else {
        this.connectedLoading = true;
        this.connected()
            .then(async () => {
              if (this.chainId != this.desireChainId) {
                await this.switchNetwork(this.desireChainId);
              }
              this.connectedLoading = false;
            })
            .catch((err) => {
              if (err.code === -32002) {
                message.error(this.$t("wallet.openWallet"));
              }
              this.connectedLoading = false;
            });
      }
    },
  },
  created() {
  },
  mounted() {
    let default_chainId;
    for (let _chainId in Network) {
      default_chainId = _chainId;
      break;
    }
    detectEthereumProvider()
        .then(async (provider) => {
          if (!provider) {
            return;
          }
          const ethereum = window.ethereum;
          ethereum.on("disconnect", () => {
            this.updateAccount(null);
          });
          ethereum.on("chainChanged", (chainId) => {
            this.$store.commit("SET_CHAINID", chainId);
            if (this.networkObj[chainId]) {
              window.location.reload();
            }
          });
          ethereum.on("accountsChanged", (accounts) => {
            console.log("accountsChanged", accounts);
            // this.$store.dispatch("updateProduct", "DEFI");
            if (accounts.length > 0) {
              this.account && this.updateAccount(accounts[0]);
            } else {
              this.updateAccount(null);
            }
          });
          let chainId = await ethereum.request({
            method: "eth_chainId",
          });
          this.$store.commit("SET_CHAINID", chainId);
          if (this.networkObj[chainId]) {
            this.$store.commit("SET_desireChainId", chainId);
          } else {
            this.$store.commit("SET_desireChainId", default_chainId);
          }
          ethereum
              .request({method: "eth_accounts"})
              .then((accounts) => {
                if (accounts && accounts.length > 0) {
                  this.$store.dispatch("connected");
                }
              });
        })
        .catch((err) => {
          console.log(err);
        });
  },
};
</script>
<style>
.network-span {
  margin-left: 8px;
}
</style>
<style lang='scss' scoped>
button {
  border: none;
  height: 40px;
  border-radius: 9px;
  transition: 0.5s;
  //border: 1px solid #00FFBC;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  background: #DBC253;
  &:hover,
  &:focus,
  &:active, &.active-button {
    background: #DBC253;
    color: #111;
    //border: 1px solid #DBC253;
  }
}

.ant-select-dropdown-menu-item{
  .change-network-img {
    filter: grayscale(100%) ;
  }
}
.change-network {
  margin-right: 40px;
  height: 40px;
  background: #DBC253;
  font-size: 16px;
  border-radius: 9px;
  color: #333;
  font-weight: bold;
  transition: 0.5s;
  .change-network-img {
    width: 20px;
    filter: grayscale(100%) invert(100%);

  }

  /deep/ .ant-select-selection--single {
    height: 40px;
    //border: 1px solid #00FFBC;
    border-radius: 9px;
    background: transparent;

    .ant-select-selection__rendered {
      line-height: 40px;
      margin-left: 30px;
      margin-right: 40px;

      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ant-select-arrow {
      width: 22px;
      height: 22px;
      right: 20px;
      transform: translateX(10px);
      margin-top: -10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/down-light.svg");
      opacity: .6;

      i {
        display: none;
      }
    }
  }
}

.drop-network {
  .ant-select-dropdown-menu {
    &-item {
      padding: 0.16rem 0.24rem;
      line-height: 0.36rem;

      a {
        font-size: 0.28rem;
      }
    }
  }
}
</style>
