<template>
  <div id="app">
    <dropx/>
  </div>
</template>

<script>
import dropx from "./components/dropx";
import { mobileRem } from "@/common/rem";
export default {
  name: 'App',
  components: {
    dropx
  },
  created() {
    mobileRem()
  }
}
</script>

<style>
</style>
